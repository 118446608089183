/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

function Pagination({ currentPage, pageCount, onPageChange }) {
  const pages = [];
  for (let i = 1; i <= pageCount; i++) {
    pages.push(
      <a
        key={i}
        className={`pagination-link ${i === currentPage ? "is-current" : ""}`}
        onClick={() => onPageChange(i)}
        aria-label={`Goto page ${i}`}
      >
        {i}
      </a>
    );
  }

  return (
    <nav className="pagination is-rounded is-centered is-desktop">
      <button
        className="pagination-previous"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      <button
        className="pagination-next"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
      >
        Next page
      </button>
      <ul className="pagination-list">{pages}</ul>
    </nav>
  );
}

export default Pagination;
