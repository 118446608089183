import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    *,
    *::after,
    *::before {
        box-sizing: border-box;
        margin: 0;
        padding : 0;
        scrollbar-width: none;
    -ms-overflow-style: none;
    }
   
    ::-webkit-scrollbar {
    display: none;
    }

    body {
        color: ${({ theme }) => theme.text};
        font-family: 'Roboto', sans-serif;
        letter-spacing: .6px;
        overflow: hidden;
    }
`;
