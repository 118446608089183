export const lightTheme = {
  text: "rgb(20, 20, 20)",
  text2: "rgb(55, 55, 55)",
  textInvert: "rgb(245, 245, 245)",
  textInvert2: "rgb(210, 210, 210)",
  primary: "rgb(252,206,22)",
  primary2: "rgba(173,47,29,0.8)",
  bg: "rgb(252, 252, 252)",
  bg2: "rgb(225, 225, 225)",
};

export const darkTheme = {
  text: "rgb(245, 245, 245)",
  text2: "rgb(210, 210, 210)",
  textInvert: "rgb(20, 20, 20)",
  textInvert2: "rgb(55, 55, 55)",
  primary: "rgb(200, 117, 196)",
  primary2: "rgb(200, 117, 147)",
  bg: "rgb(3, 3, 3)",
  bg2: "rgb(30,30,30)",
};
