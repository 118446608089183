import React from "react";
import Layout from "../../../components/user/Layout/Layout";
import DetailOpini from "../../../components/user/ELibraryPage/DetailOpini";

const DOpini = () => {
  return (
    <Layout>
      <DetailOpini />
    </Layout>
  );
};

export default DOpini;
