import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../../utils";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Cookies from "universal-cookie";

const AddOpini = () => {
  const [judul, setJudul] = useState("");
  const [description, setDescription] = useState("");
  const [penulis, setPenulis] = useState("");
  const [gambar, setGambar] = useState("");
  const [preview, setPreview] = useState("");
  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("token") === undefined) history.push("/login");
  }, []);

  const handleOnChange = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setGambar(image);
    setPreview(URL.createObjectURL(image));
  };

  const saveOpini = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("judul", judul);
    formData.append("penulis", penulis);
    formData.append("description", description);
    formData.append("gambar", gambar);
    try {
      await axios.post(API_URL + "/opini", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      history.push("/adminOpini");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="columns is-centered mt-5 mb-5">
        <div className="column is-half">
          <form onSubmit={saveOpini}>
            <div className="field">
              <label className="label">Judul Opini</label>
              <div className="control">
                <input
                  type="text"
                  className="input"
                  value={judul}
                  onChange={(e) => setJudul(e.target.value)}
                  placeholder="Judul Opini"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Isi Opini</label>
              <div className="control">
                <CKEditor editor={ClassicEditor} onChange={handleOnChange} />
              </div>
            </div>
            <div className="field">
              <label className="label">Penerbit</label>
              <div className="control">
                <input
                  className="input"
                  value={penulis}
                  onChange={(e) => setPenulis(e.target.value)}
                  placeholder="Penulis"
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Gambar Opini</label>
              <div className="control">
                <div className="file">
                  <label className="file-label">
                    <input
                      type="file"
                      className="file-input"
                      onChange={loadImage}
                    />
                    <span className="file-cta">
                      <span className="file-label">Choose a file ..</span>
                    </span>
                    {preview ? (
                      <figure className="image is-128x128 mb-5">
                        <img src={preview} alt="Preview image" />
                      </figure>
                    ) : (
                      ""
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="field">
              <div className="control">
                <button type="submit" className="button is-success">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddOpini;
