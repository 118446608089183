import React from "react";
import LoginPage from "../../components/admin/LoginPage/LoginPage";

const Login = () => {
  return (
    <LoginPage />
  )
};

export default Login;
