import React from "react";
import Layout from "../../../components/admin/LayoutAdmin/Layout";
import ProgramkerjaPage from "../../../components/admin/ProgramkerjaPage/ProgramkerjaPage";

const Programkerja = () => {
  return (
    <Layout>
      <ProgramkerjaPage />
    </Layout>
  );
};

export default Programkerja;
