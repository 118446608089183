import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { API_URL } from "../../../utils";
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import { GrEdit } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";
import Cookies from "universal-cookie";
import { Table } from "antd";

const ArtikelPage = () => {
  const [artikels, setArtikels] = useState([]);
  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get("token")) {
      history.push("/login");
    } else {
      getArtikels();
    }
  }, []);

  const getArtikels = async () => {
    try {
      const response = await axios.get(API_URL + "/artikel");
      setArtikels(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteArtikel = async (artikelId) => {
    try {
      await axios.delete(API_URL + "/artikel" + `/${artikelId}`);
      getArtikels();
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "No",
      // dataIndex: "id",
      width: 30,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Judul Artikel",
      dataIndex: "judul",
      width: 150,
    },
    {
      title: "Isi",
      dataIndex: "description",
      width: 150,
      render: (description) => (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      ),
    },
    {
      title: "Penulis",
      dataIndex: "penulis",
      width: 100,
    },
    {
      title: "Gambar",
      dataIndex: "url",
      render: (url) => (
        <img src={url} alt="image" style={{ maxWidth: "100px" }} />
      ),
      width: 100,
    },
    {
      title: "Action",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <span>
          <Link to={`/editArtikel/${record.id}`} style={{ marginRight: "8px" }}>
            <GrEdit />
          </Link>
          <a onClick={() => deleteArtikel(record.id)}>
            <BsTrash />
          </a>
        </span>
      ),
    },
  ];

  return (
    <div className="container mt-5 mb-5 is-relative">
      <Link className="button is-small mr-3 is-primary mb-3" to="/addArtikel">
        <span className="icon">
          <IoMdAdd />
        </span>
        <span>Tambah Artikel</span>
      </Link>
      <Table
        columns={columns}
        dataSource={artikels}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 240 }}
        size="small"
        bordered
      />
    </div>
  );
};

export default ArtikelPage;
