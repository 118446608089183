import React from "react";
import Layout from "../../../../components/admin/LayoutAdmin/Layout";
import AddOpini from "../../../../components/admin/OpiniPage/AddOpini";

const CreateOpini = () => {
  return (
    <Layout>
      <AddOpini />
    </Layout>
  );
};

export default CreateOpini;
