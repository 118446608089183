import React from "react";
import Layout from "../../../components/admin/LayoutAdmin/Layout";
import AddProgramkerja from "../../../components/admin/ProgramkerjaPage/AddProgramkerja";

const CreateProgramkerja = () => {
  return (
    <Layout>
      <AddProgramkerja />
    </Layout>
  );
};

export default CreateProgramkerja;
