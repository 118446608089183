import React from "react";
import Layout from "../../../components/admin/LayoutAdmin/Layout";
import Edit from "../../../components/admin/ProgramkerjaPage/EditProgramkerja";

const EditProgramkerja = () => {
  return (
    <Layout>
      <Edit />
    </Layout>
  );
};

export default EditProgramkerja;
