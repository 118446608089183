import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API_URL } from "../../../utils";
import axios from "axios";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Cookies from "universal-cookie";
import { Select } from "antd";

const EditProgramkerja = () => {
  const [judul, setJudul] = useState("");
  const [description, setDescription] = useState("");
  const [progress, setProgress] = useState("");
  const [gambar, setGambar] = useState("");
  const [idStatus, setStatusId] = useState("");
  const [msg, setMsg] = useState("");
  const [preview, setPreview] = useState("");
  const history = useHistory();
  const { id } = useParams();
  const cookies = new Cookies();

  const [status, setStatus] = useState([]);

  useEffect(() => {
    getProkers();
    getStatus();
    token();
  }, []);

  const token = () => {
    if (cookies.get("token") === undefined) history.push("/login");
  };

  const handleOnChange = (e, editor) => {
    const data = editor.getData();
    setDescription(data);
  };

  const loadImage = (e) => {
    const image = e.target.files[0];
    setGambar(image);
    setPreview(URL.createObjectURL(image));
  };

  const updateArtikel = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("judul", judul);
    formData.append("statusId", idStatus);
    formData.append("description", description);
    formData.append("gambar", gambar);
    try {
      await axios.patch(API_URL + "/proker" + `/${id}`, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
      history.push("/adminProker");
    } catch (error) {
      setMsg(error.response.data.msg);
    }
  };

  const getProkers = async () => {
    const response = await axios.get(API_URL + `/proker/${id}`);
    setJudul(response.data.data[0].judul);
    setDescription(response.data.data[0].description);
    setStatusId(response.data.data[0].Status.id);
    setProgress(response.data.data[0].Status.name);
    setGambar(response.data.data[0].gambar);

    // console.log("res", response.data.data[0].Status.name);
  };

  const getStatus = async () => {
    try {
      const res = await axios.get(API_URL + "/status");
      setStatus(res.data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="columns is-centered mt-5 mb-5">
      <div className="column is-half">
        <form onSubmit={updateArtikel}>
          <h1 className="has-text-centered">{msg}</h1>
          <div className="field">
            <label className="label">Judul Program Kerja</label>
            <div className="control">
              <input
                type="text"
                className="input"
                value={judul}
                onChange={(e) => setJudul(e.target.value)}
                placeholder="Judul Buku"
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Status</label>
            <div className="control">
              <Select
                showSearch
                placeholder="status"
                options={status?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                onChange={(e) => {
                  setStatusId(e);
                  setProgress(e);
                }}
                value={progress}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Description</label>
            <div className="control">
              <CKEditor
                editor={ClassicEditor}
                onChange={handleOnChange}
                data={description}
              />
            </div>
          </div>
          <div className="field">
            <label className="label">Gambar</label>
            <div className="control">
              <div className="file">
                <label className="file-label">
                  <input
                    type="file"
                    className="file-input"
                    onChange={loadImage}
                  />
                  <span className="file-cta">
                    <span className="file-label">Choose a image ..</span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          {preview ? (
            <figure className="image is-128x128">
              <img src={preview} alt="Preview image" />
            </figure>
          ) : (
            ""
          )}

          <div className="field">
            <div className="control">
              <button type="submit" className="button is-success">
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProgramkerja;
