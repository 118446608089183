import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { API_URL } from "../../../utils";
import { IoMdAdd } from "react-icons/io";
import { GrEdit } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";

const OpiniPage = () => {
  const [opini, setOpini] = useState([]);
  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    getOpini();
    if (cookies.get("token") === undefined) history.push("/login");
  }, []);

  const getOpini = async () => {
    const response = await axios.get(API_URL + "/opini");
    setOpini(response.data);
  };

  const deleteOpini = async (opiniId) => {
    try {
      await axios.delete(API_URL + "/opini" + `/${opiniId}`);
    } catch (error) {}
  };
  return (
    <>
      <div className="container mt-5 mb-5 is-relative">
        <Link className="button is-small mr-3 is-primary mb-3" to="/addOpini">
          <span className="icon">
            <IoMdAdd />
          </span>
          <span>Tambah Opini</span>
        </Link>
        <div style={{ overflowX: "auto" }}>
          <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable">
            <thead>
              <tr>
                <th>No</th>
                <th>Judul Opini</th>
                <th>Isi</th>
                <th>Oleh</th>
                <th>Gambar</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {opini.map((opini, index) => (
                <tr key={opini.id}>
                  <td>{index + 1}</td>
                  <td>{opini.judul}</td>
                  <td>
                    <div
                      dangerouslySetInnerHTML={{ __html: opini.description }}
                    />
                  </td>
                  <td>{opini.penulis}</td>
                  <td>
                    <figure className="image is-4by3">
                      <img src={opini.url} alt="image" />
                    </figure>
                  </td>
                  <td>
                    <div className="columns is-mobile">
                      <div className="column">
                        <Link
                          className="button is-small is-info"
                          to={`editOpini/${opini.id}`}
                        >
                          <span className="icon">
                            <GrEdit />
                          </span>
                          <span>EDIT</span>
                        </Link>
                      </div>
                      <div className="column">
                        <a
                          className="button is-danger is-small"
                          onClick={() => deleteOpini(opini.id)}
                        >
                          <span>
                            <BsTrash />
                          </span>
                          <span>HAPUS</span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default OpiniPage;
