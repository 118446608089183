import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";
import { API_URL } from "../../../utils";
import { BsTrash } from "react-icons/bs";
import { Table, Typography } from "antd";

const AlbumFoto = () => {
  const [albums, setAlbums] = useState([]);
  const [nameAlbum, setNameAlbum] = useState([]);

  const getAlbum = async () => {
    await axios
      .get(API_URL + "/foto")
      .then((res) => {
        if (res.status === 200) {
          setAlbums(res.data);
        }
      })
      .catch((err) => console.log(err, "err"));
  };

  const getNameAlbum = async () => {
    await axios
      .get(API_URL + "/foto/nama_album")
      .then((res) => {
        if (res.status === 200) {
          setNameAlbum(res.data);
        }
      })
      .catch((err) => console.log(err, "err"));
  };

  useEffect(() => {
    getNameAlbum();
    getAlbum();
  }, []);

  const deleteAlbum = async (fotoId) => {
    try {
      await axios.delete(API_URL + "/foto" + `/${fotoId}`);
      getAlbum();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "No",
      width: 30,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Nama Album",
      dataIndex: "albumId",
      width: 150,
      render: (albumId) => {
        const album = nameAlbum.find((item) => item.id === Number(albumId));
        const name = album?.nama;
        return <Typography>{name}</Typography>;
      },
    },
    {
      title: "Gambar",
      dataIndex: "image",
      render: (image) => {
        return (
          <img
            src={API_URL + `/foto/${image}`}
            alt="image"
            style={{ maxWidth: "100px" }}
          />
        );
      },
      width: 100,
    },
    {
      title: "Action",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <a onClick={() => deleteAlbum(record.id)}>
          <BsTrash />
        </a>
      ),
    },
  ];

  return (
    <div className="container mt-5 mb-5 is-relative">
      <Link className="button is-small mr-3 is-primary mb-3" to="/addAlbum">
        <span className="icon">
          <IoMdAdd />
        </span>
        <span>Tambah Album Foto</span>
      </Link>
      <Table
        columns={columns}
        dataSource={albums}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 240 }}
        size="small"
        bordered
      />
    </div>
  );
};

export default AlbumFoto;
