import React from "react";
import Layout from "../../../../components/admin/LayoutAdmin/Layout";
import AddBook from '../../../../components/admin/BukuPage/AddBook';
const CreateBuku = () => {
    return (
        <Layout>
            <AddBook />
        </Layout>
    )
};

export default CreateBuku;
