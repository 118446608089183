import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { API_URL } from "../../../utils";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { message, Upload, Form, Input, Button } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const AddAlbum = () => {
  const history = useHistory();
  const cookies = new Cookies();
  const [form] = Form.useForm();

  useEffect(() => {
    if (cookies.get("token") === undefined) history.push("/login");
  }, []);

  const saveAlbum = async (value) => {
    await form.validateFields().then(async () => {
      const formData = new FormData();
      formData.append("albumName", value?.albumName);

      value?.gambar?.forEach((item) => {
        formData.append("gambar", item.originFileObj);
      });

      await axios
        .post(API_URL + "/foto/add", formData, {
          headers: {
            "Content-type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res?.status === 201) {
            message.success("Sukses Nambah Album");
            history.push("/adminAlbum");
          }
        })
        .catch((err) => {
          if (err.response?.status === 400) {
            message.error(err.response?.data?.msg);
          }
        });
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    // return e?.fileList?.originFileObj;
    return e?.fileList;
  };

  return (
    <div className="columns is-centered mt-5 mb-5">
      <div className="column is-half">
        <Form
          autoComplete="off"
          name="basic"
          layout="vertical"
          // style={{ maxWidth: 600 }}
          onFinish={saveAlbum}
          onSubmitCapture={(e) => e.preventDefault()}
          form={form}
        >
          <Form.Item label="Nama Album" name="albumName">
            <Input />
          </Form.Item>
          <Form.Item
            label="Foto"
            name="gambar"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload.Dragger action="" listType="picture-card" accept="image/*">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from
                uploading company data or other banned files.
              </p>
            </Upload.Dragger>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Simpan
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddAlbum;
