import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { API_URL } from "../../../utils";
import { Row, Col, Card, Space, Button } from "antd";

const Programkerja = () => {
  const [proker, setProker] = useState([]);
  const [status, setStatus] = useState([]);
  const [param, setParam] = useState("");

  useEffect(() => {
    getProkers();
    getStatus();
  }, [param]);

  const getProkers = async () => {
    try {
      const res = await axios.get(API_URL + `/proker?status=${param}`);
      setProker(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const res = await axios.get(API_URL + "/status");
      setStatus(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="has-text-centered">
      <div
        className="has-text-left mt-6"
        style={{ borderLeft: "6px solid red" }}
      >
        <p className="is-size-6 pl-2 has-text-weight-bold">Program Kerja</p>
      </div>
      <hr />
      <Space wrap style={{ marginBottom: 20 }}>
        {status.map((item) => (
          <Button type="dashed" onClick={() => setParam(item.id)}>
            {item.name}
          </Button>
        ))}
      </Space>
      <Row
        gutter={[16, 16]}
        justify="center"
        align="middle"
        style={{ margin: 0, padding: 0 }}
      >
        {proker.map((item) => (
          <Col xs={24} sm={12} md={8} lg={6} xl={8} key={item.id}>
            <Card
              hoverable
              style={{ width: "100%" }}
              cover={<img alt="example" src={item.url} />}
            >
              <Card.Meta
                title={item.judul}
                description={
                  <div dangerouslySetInnerHTML={{ __html: item.description }} />
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Programkerja;
