import React from "react";
import Layout from "../../../../components/admin/LayoutAdmin/Layout";
import OpiniPage from "../../../../components/admin/OpiniPage";

const Opini = () => {
  return (
    <Layout>
      <OpiniPage />
    </Layout>
  );
};

export default Opini;
