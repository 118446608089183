import React, { useState, useEffect } from "react";
import Cookies from "universal-cookie";
import { useHistory } from "react-router";
import axios from "axios";
import { API_URL } from "../../../utils";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { GrEdit } from "react-icons/gr";
import { BsTrash } from "react-icons/bs";
import { IoMdAdd } from "react-icons/io";

const ProgramkerjaPage = () => {
  const [prokers, setProkers] = useState([]);

  const history = useHistory();
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get("token")) {
      history.push("/login");
    } else {
      getProkers();
    }
  }, []);

  const getProkers = async () => {
    try {
      const res = await axios.get(API_URL + "/proker");
      setProkers(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteProker = async (id) => {
    try {
      await axios.delete(API_URL + "/proker" + `/${id}`);
      getProkers();
    } catch (error) {
      console.error(error);
    }
  };

  const columns = [
    {
      title: "No",
      // dataIndex: "id",
      width: 30,
      render: (text, record, index) => index + 1,
    },
    {
      title: "Judul",
      dataIndex: "judul",
      width: 150,
    },
    {
      title: "Description",
      dataIndex: "description",
      width: 150,
      render: (description) => (
        <div dangerouslySetInnerHTML={{ __html: description }} />
      ),
    },
    {
      title: "Gambar",
      dataIndex: "url",
      render: (url) => (
        <img src={url} alt="image" style={{ maxWidth: "100px" }} />
      ),
      width: 100,
    },
    {
      title: "Status",
      width: 120,
      dataIndex: "Status",
      render: (Status) => <p>{Status.name}</p>,
    },
    {
      title: "Action",
      width: 100,
      fixed: "right",
      render: (text, record) => (
        <span>
          <Link to={`/editProker/${record.id}`} style={{ marginRight: "8px" }}>
            <GrEdit />
          </Link>
          <a onClick={() => deleteProker(record.id)}>
            <BsTrash />
          </a>
        </span>
      ),
    },
  ];

  return (
    <div className="container mt-5 mb-5 is-relative">
      <Link className="button is-small mr-3 is-primary mb-3" to="/createProker">
        <span className="icon">
          <IoMdAdd />
        </span>
        <span>Tambah Program Kerja</span>
      </Link>
      <Table
        columns={columns}
        dataSource={prokers}
        pagination={{ pageSize: 50 }}
        scroll={{ y: 240 }}
        size="small"
        bordered
      />
    </div>
  );
};

export default ProgramkerjaPage;
