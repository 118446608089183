import React from "react";
import Layout from "../../../components/user/Layout/Layout";
import PublikasiOpini from "../../../components/user/ELibraryPage/PublikasiOpini";

const Opini = () => {
  return (
    <>
      <Layout>
        <PublikasiOpini />
      </Layout>
    </>
  );
};

export default Opini;
