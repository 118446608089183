/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import { API_URL } from "../../../utils";
import Meta from "../../../utils/Meta";
import moment from "moment";
import { AiOutlineWhatsApp } from "react-icons/ai";

const DetailArtikel = () => {
  const [judul, setJudul] = useState("");
  const [isi, setIsi] = useState("");
  const [url, setUrl] = useState("");
  const [oleh, setOleh] = useState("");
  const [date, setDate] = useState("");
  const { id } = useParams();

  useEffect(() => {
    getArtikelById();
  }, []);

  const getArtikelById = async () => {
    const response = await axios.get(API_URL + `/artikel/${id}/${judul}`);
    setJudul(response.data.judul);
    setIsi(response.data.description);
    setUrl(response.data.url);
    setOleh(response.data.penulis);
    setDate(response.data.createdAt);
  };

  const location = useLocation();

  console.log(location, "p");

  return (
    <>
      <Meta title={judul} desc={isi} imageUrl={url} />
      <div className="container">
        <div className="card my-5 mx-4">
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <span
                  className="title is-size-4 has-text-justified"
                  style={{ fontSize: "2rem" }}
                >
                  {judul}
                </span>
                <p className="subtitle is-6 mt-1">
                  {/* {moment.locale()} */}
                  {moment(date).format("dddd, D MMMM YYYY")}
                  <br />
                  {oleh}
                </p>
                <div className="card-image">
                  <figure className="image is-4by3">
                    <img src={url} alt="Placeholder image" />
                  </figure>
                </div>
                <div
                  className="content mt-2 is-size-6-mobile has-text-justified"
                  dangerouslySetInnerHTML={{ __html: isi }}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <p>Bagikan Ke : </p>
                  <a
                    // href={`whatsapp.com/send?text=https://himapersis.id${location.pathname}`}
                    href={`whatsapp://send?text=https%3A%2F%2Fhimapersis.id${location.pathname}`}
                    rel="nofollow noopener"
                    target="_blank"
                    className="button is-success is-small is-rounded is-focused"
                  >
                    <AiOutlineWhatsApp size={20} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailArtikel;
